/* You can add global styles to this file, and also import other style files */
//@import '../node_modules/angular-calendar/css/angular-calendar.css';

// angular material
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
// regular style toast
@import '~ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import '~ngx-toastr/toastr-bs4-alert';

// // if you'd like to use it without importing all of bootstrap it requires
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';
/// @import '~ngx-toastr/toastr.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* You can add global styles to this file, and also import other style files */
mat-form-field {
    width: 100%;
    }
.button100{
    width: 100%;
  }